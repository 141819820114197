import React from 'react'

import { Layout } from '../../components/auth/Layout'
import { RecoverPasswordContent } from '../../components/auth/RecoverPasswordContent'
import { AccountType } from '../../requests'

const SubjectsRecoverPasswordPage = () => {
  return (
    <Layout accountType={AccountType.Subject}>
      <RecoverPasswordContent accountType={AccountType.Subject} />
    </Layout>
  )
}

export default SubjectsRecoverPasswordPage
export const Head = () => <title>Datacapt - Recover Password</title>
